import React, { useEffect, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import Footer from "../../components/Footer/Footer";

import error_icon from "./error.svg";
import "./home.css";

const Home = (props) => {
  let history = useHistory();
  const [ isLoading, setIsLoading ] = useState(false)
  const { setLinkId, setTitle, LinkId } = props;
  const uniqueId = Date.now() + Math.floor(Math.random() * 5);

  useEffect(() => {
    setLinkId(uniqueId);
    setTitle("");
  }, []);

  function handleChange(event) {
    // Here, we invoke the callback with the new value
    props.onChange(event.target.value);
  }

  const handleKeypress = (event) => {
    //it triggers by pressing the enter key
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function handleSubmit(event) {
    var updatedLink;
   
    let titleLength = props.Title.length;

    if (titleLength < 3) {
      const errorMessage = document.getElementById("errorMessage");
      errorMessage.style.visibility = "visible";

      setTimeout(() => {
        errorMessage.style.visibility = "hidden";
      }, 4000);
    } else {
      setIsLoading(true)

      setTimeout(() => {
        fetch("/home", {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            title: props.Title,
            linkId: LinkId,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data) {
              setIsLoading(true)
              history.push(`/link/${LinkId}`);
            }
          });
      }, 1000);
    }
  }

  return (
    <div className="sm:w-12/12 mx-auto min-h-screen relative">
      <div id=" " className=" md:w-10/12 mx-auto sm:px-10 px-5  pb-20">
        <div className="flex w-full  pb-10  p-1 home_container md:space-x-2 max-w-full ">
          <div className="lg:w-6/12 sm:pt-24 pt-16 ">
            <div className="lg:w-full">
              <p className="sm:text-4xl sz35_text font-extrabold tracking-tighter leading-10">
                The best way to take questions from your audience
              </p>
            </div>

            <div className="pt-12">
              <p className="lg:w-9/12 text-base font-bold text-dark_grey">
                What’s the title of your event?
              </p>

              <div className="mt-2 mb-4 lg:w-9/12">
                <div id="showError" className="space-y-4">
                  <input
                    type="text"
                    name="title"
                    id="event-title"
                    required
                    autoFocus
                    placeholder="E.g. AMA with Charles Dairo"
                    onChange={handleChange}
                    onKeyPress={handleKeypress}
                    className="rounded-md text-black-500 pl-3 focus:outline-none focus:ring focus:-mid_blue shadow w-full h-12 placeholder-mid_gray"
                  />

                  <button
                    type="submit"
                    className="blue_button text-lg font-bold focus:outline-none focus:ring focus:-mid_blue disabled:cursor-not-allowed disabled:opacity-50"
                    onClick={handleSubmit}
                    disabled={isLoading}
                  >
                    <div className="flex justify-center items-center space-x-3">
                      {!isLoading ?
                        <div>Get your question link</div>
                        :
                        <div className="flex justify-center items-center space-x-3">
                          <div className="-mt-1" role="status">
                            <svg aria-hidden="true" className="inline w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#ccc"/>
                            </svg>
                          </div> 
                          <div>Loading...</div>
                        </div>
                      }
                    </div>
                  </button>

                  <div
                    id="errorMessage"
                    className="homepage_error flex items-center text-base"
                  >
                    <div className="w-5 h-5 mr-5">
                      <img alt="error" src={error_icon} />
                    </div>
                    <div>Title must be minimum of 3 characters</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="space-y-2 lg:w-9/12 ">
              {/* <p className='text-3xl font-extrabold tracking-tighter sm:mt-16 mt-12 leading-6 '>Get your question link</p> */}
              <p className=" opacity-90 leading-6 mt-4 sz18_text">
                Make your Q&A sessions faster, or get questions ahead of your
                event. Simply share a question link with your audience.
              </p>
              <p className=" opacity-70 leading-6 sz14_text ">
                No registration required, it’s 100% free and super-fast.
              </p>
            </div>
          </div>

          <div id="hide_this " className="lg:w-6/12 pt-6 home_background">
            <div className="phone_mockup image-rendering">
              {/* <img alt='demo' src={demo} className='object-center'/>   */}
            </div>
            {/* <img alt='demo' src={demo}   className='image-rendering rotate_this hp_image ' /> */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withRouter(Home);
