import React, { useState, useEffect, useMemo } from "react";
import { withRouter } from "react-router-dom";
import "./Admin.css";

import AdminList from "../../components/Admin/AdminList";

const Admin = () => {
  const [AdminTitle, setAdminTitle] = useState([]);
  const [AdminLinkid, setAdminLinkid] = useState([]);
  const [AdminDate, setAdminDate] = useState([]);
  const [AdminId, setAdminId] = useState([]);
  const [AdminTot, setAdminTot] = useState(0);
  let n;
  const [NumQtn, setNumQtn] = useState([]);
  const [TotalQ, setTotalQ] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const numberOfPages = Math.ceil(AdminTot / 10);

  const updateAdmin = () => {
    setAdminTitle([]);
    setAdminLinkid([]);
    setAdminDate([]);
    setAdminId([]);
    setNumQtn([])

    fetch(`/api/admin/links?page=${page}`)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setIsLoading(false);
          n = data.length;
          for (let i = 0; i < n; i++) {
            //SET STATE ARRAY WITH DATA FROM DATABASE
            setAdminTitle((AdminTitle) => [...AdminTitle, data[i].title]);
            setAdminLinkid((AdminLinkid) => [...AdminLinkid, data[i].linkid]);
            setAdminDate((AdminDate) => [...AdminDate, data[i].created]);
            setAdminId((AdminId) => [...AdminId, data[i].id]);
            setNumQtn((NumQtn) => [...NumQtn, data[i].questiondetails_count - 1]);
          }
        }
      })
      .catch((err) => console.log("unable to retrive data"));
  };

  const getSummary = () => {
    fetch("/api/admin/summary")
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setTotalQ(data.totalQuestionsAsked);
          setAdminTot(data.totalLinksCreated);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    updateAdmin();
  }, [page]);

  useMemo(() => {
    getSummary();
  }, []);

  return (
    <div id="container" className="md:w-4/5 mx-auto px-10 py-28">
      <div className="space-y-5 text-gray adminTableTitle font-extrabold">
        <div className="flex">
          <div className="py-0.5">NUMBER OF LINKS CREATED </div>
          <span className="adminNumCell rounded ml-3 py-0.2 px-1.5 float-right ">
            {AdminTot}
          </span>
        </div>

        <div className="flex">
          <div className="py-0.5">NUMBER OF QUESTIONS ASKED</div>
          <span className="adminNumCell rounded ml-3 py-0.2 px-1.5 float-right ">
            {TotalQ}
          </span>
        </div>
      </div>

      {isLoading ? (
        <div className="px-10 px-2 mt-5 py-48 bg-white text-grey rounded-2xl h-auto flex items-center justify-center"> Loading... </div>
      ) : (
        <div>
          <div className="mt-9 text-xs">
            <div className="md:px-10 px-2 h-auto flex font-semibold items-center space-x-1.5 ">
              <div className="w-2/12 cellContent adminTableTitle">LINKID</div>
              <div className="w-5/12 cellContent adminTableTitle">TITLE</div>
              <div className="md:w-1/12 w-2/12 cellContent adminTableTitle">NUM</div>
              <div className="w-3/12 adminTableTitle">CREATED</div>
            </div>
          </div>

          <AdminList
            AdminTitle={AdminTitle}
            AdminLinkid={AdminLinkid}
            AdminDate={AdminDate}
            AdminId={AdminId}
            NumQtn={NumQtn}
            n={n}
          />

          <div className="flex justify-between text-gray">
            <div>
              Page {page} of {numberOfPages}
            </div>
            <div className="flex space-x-5 font-extrabold">
              <button
                type="submit"
                className="text-lg focus:outline-none disabled:cursor-not-allowed disabled:opacity-50"
                disabled={page === 1}
                onClick={() => setPage(page - 1)}
              >
                PREV
              </button>

              <button
                type="submit"
                className="text-lg focus:outline-none disabled:cursor-not-allowed disabled:opacity-50"
                disabled={page === numberOfPages}
                onClick={() => setPage(page + 1)}
              >
                NEXT
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(Admin);