import React, { useState, useEffect } from "react";

const Consent = () => {
  const [isVisible, setIsVisible] = useState(true); 

  const handleAccept = () => {
    setIsVisible(false);
    localStorage.setItem("cookieConsent", "true"); 
  };

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (consent) {
      setIsVisible(false);
    }
  }, []);

  return (
    isVisible && (
      <div id="gdpr-cookie-message-wrapper">
        <div id="gdpr-cookie-message">
          <h4>Cookies &amp; Privacy</h4>
          <p>
            Questionwave uses cookies to ensure you get the best experience.<br/>
            <a href="/legal">More information</a>
          </p>
          <p>
            <button id="gdpr-cookie-accept" type="button" onClick={handleAccept}>
              Accept Cookies
            </button>
          </p>
        </div>
      </div>
    )
  );
};

export default Consent;
